@font-face {
  font-family: 'Brother1816';
  src: url('../fonts/Brother1816-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Brother1816';
  src: url('../fonts/Brother1816-BookItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Brother1816';
  src: url('../fonts/Brother1816-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Brother1816';
  src: url('../fonts/Brother1816-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Brother1816';
  src: url('../fonts/Brother1816-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Brother1816';
  src: url('../fonts/Brother1816-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

h1, h2, h3, h4, h5, h6, p {
  font-family: 'Brother1816', sans-serif;
}
